.contact{
    background-image: url(../assets/contactbg.png);
    background-size:cover;
}

.contact-text h1{
    font-size:8em;
    line-height:75px;
    color:#f1f7f6;
}

.contact-text span{
    color:#00DF81;
}

.contact-form{
    background-color: #00140f6c;;
    backdrop-filter: blur(1.5px); /* Apply the blur effect */
    -webkit-backdrop-filter: blur(1.5px);
    padding:30px;
    border-radius: 25px;
    border: 1.5px solid rgba(241, 247, 246, 0.25);
}

.form-control {
    border:2px solid #004434;
    background: #004434 !important;
    border-radius: 25px !important;
    color: #f1f7f6 !important;
}

.form-control:focus{
    border: 1.5px solid rgba(241, 247, 246, 0.25);
    box-shadow:none!important;
}

.form-control:active{
    border: 1.5px solid rgba(241, 247, 246, 0.25);
    box-shadow:none!important;
}

.input-group-text {
    border:2px solid #004434!important;
    background: #004434 !important;
    border-top-left-radius: 25px !important;
    border-right: 1px solid #ffffff3b !important;
    border-bottom-left-radius: 25px !important;
    color: #f1f7f6 !important;
    border-top-right-radius:0px!important;
    border-bottom-right-radius:0px!important;
}

.phone-control{
    border-top-left-radius: 0!important;
    border-bottom-left-radius: 0 !important;
    border-left: 1px solid #ffffff3b !important;
}

.form-group {
    margin-bottom: 15px;
  }
  
  .input-group-text {
    background-color: #e9ecef;
    border: 1px solid #ced4da;
  }

 /* Custom styling for dropdown */
.custom-dropdown {
    position: relative;
  }
  
  .dropdown-input {
    appearance: none; /* Hide the default arrow */
    padding-right: 30px; /* Space for custom arrow */
  }
  
  /* Custom Arrow Styling */
  .dropdown-arrow {
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    font-size: 14px;
    color: #f1f7f6;
  }
  
  /* Placeholder Option Styling */
  .placeholder-option {
    color: #ffffff65 !important; /* Change placeholder option to red */
  }
  
  /* Customised Dropdown */
  select:invalid {
    color: #ffffff65 !important;
  }
  
  select option:not(:first-child) {
    color: #000; /* Set other options to normal text colour */
  }
  
  input::placeholder,
textarea::placeholder,
select::placeholder {
  color: #ffffff65; /* Change to your desired placeholder colour */
  opacity: 1; /* Ensure full opacity for placeholders */
}

/* Specific Styling for Input Fields */
input::placeholder {
  color: #ffffff65 !important; /* Change to red */
}

/* Specific Styling for Textarea Fields */
textarea::placeholder {
  color: #ffffff65 !important; /* Change to red */
}

/* Styling for Select Placeholder (Doesn't work on all browsers) */
select::placeholder {
  color: #ffffff65 !important; /* Change to red */
}

/* Adjust the input field styling when the placeholder is active */
input:placeholder-shown,
textarea:placeholder-shown {
  color: #ffffff65 !important; /* Make the text of placeholder red */
}

.phone-emoji{
    padding-right:10px;
}

input:valid, 
textarea:valid,
input:not(:placeholder-shown),
textarea:not(:placeholder-shown) {
  border-color: #004434; 
  background-color: #004434!important; 
  box-shadow: none;
}

/* Optional: Style label when input has content (if you have a floating label) */
input:valid + label, 
textarea:valid + label,
input:not(:placeholder-shown) + label,
textarea:not(:placeholder-shown) + label {
  color: #00dc7e; /* Change label color */
  font-weight: bold; /* Change label style */
}


.alert-success {
    border-left: 5px solid #00DF81;
    background: #00DF8120;
    border-top: none !important;
    border-right: none !important;
    border-bottom: none !important;
    color: #f1f7f6;
}

.alert-danger {
    border-left: 5px solid #df0000;
    background: #df000020;
    border-top: none !important;
    border-right: none !important;
    border-bottom: none !important;
    color: #f1f7f6;
}