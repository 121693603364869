.reviews{
    background-color: #03624C;
    border-radius:25px;
    margin-top:-3%;
}

.reviews h1{
    color:#F1F7F6;
}

.review-box{
    background-color: rgba(4, 34, 33, 0.15);
    backdrop-filter: blur(1.5px); /* Apply the blur effect */
    -webkit-backdrop-filter: blur(1.5px); /* For Safari support */
    border: 1.5px solid rgba(241, 247, 246, 0.15);
    border-radius:20px;
    padding:15px;
}

.review-text{
    margin-top:5%;
}

hr {
    margin: 1rem 0;
    color: #fff;
    border: 0;
    border-top: 1.5px solid;
    opacity: 0.3;
}

.review-avatar{
    display: flex;
    flex-wrap: wrap;
    margin-top:2%;
    gap: 10px;
}

.review-avatar h4{
    color:#F1F7F6;
    font-size:1em;
}
.review-avatar p{
    font-size:1em;
    font-weight:600;
    line-height: px;;
    line-height: 5px;;
}

.review-avatar-box{
    vertical-align: middle;
}

.review-avatar-box img{
    border-radius: 500px;
}