.why{
    background-image: url(../assets/whybg.png);
    background-size:cover;
}

.why h1{
    color:#f1f7f6;
}

.why-left-side-1-img{
    background-image: url(../assets/workingbg.png);
    background-size:cover;
    height: 100%;
    display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: flex-end; /* Align items to the bottom */
  padding:15px;
  
  border-radius:25px;
}

.why-left-side-2-img{
    background-image: url(../assets/abstractbg.png);
    background-size:cover;
    height: 100%;
    display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: flex-start; /* Align items to the bottom */
  padding:15px;
  border-radius:25px;
}

.why-right-side-outter{
}

.why-left-side-1{
    height:400px;
    border-radius:25px;
}

.why-left-side-1-img p{
    
}

.why-right-side-1{
    height: 100%;
    background:#004434;
    border-radius:25px;
  padding:15px;
  border: 1.5px solid rgba(241, 247, 246, 0.25);
  display: flex;
  flex-direction: column; 
  justify-content: space-between; 
}

.why-row h2{
    color:#f1f7f6;
    font-size:6.5em;
    line-height: 80px;
    font-weight:500;
}

.why-row h4{
    color:#f1f7f6;
    font-size:1.25em;
}


.outer-circle {
    width: 375px;
    height: 375px;
    border-radius: 500px; 
    background-color: #021414; 
    position: relative; 
    overflow: hidden; 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .pill {
    background-color: #00DF81; 
    color: #021414;
    font-family: "Unbounded", sans-serif;
    font-weight:400;
    border-radius: 500px; 
    padding: 10px 20px; 
    position: absolute; 
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px; 
    transform-origin: center;
  }
  
  .pill-0 { transform: rotate(18.5deg) translate(-10px, -180px); }
  .pill-1 { transform: rotate(18.5deg) translate(-10px, -125px); }

  .pill-2 { transform: rotate(-34.8deg) translate(-52px, -116px); }
  
  .pill-3 { transform: rotate(-34.8deg) translate(-50px, -60px); }
  
  .pill-4 { transform: rotate(25deg) translate(84.5px, -73px); }

  .pill-5 { transform: rotate(25deg) translate(90px, -16px); }
  .pill-6 { transform: rotate(25deg) translate(68px, 40px); }
  .pill-7 { transform: rotate(-7deg) translate(-125px, 60px); }
  .pill-8 { transform: rotate(10deg) translate(0px, 150px); }
  
  .pill-icon {
    font-size: 1.5rem;
  }
  
  .pill-title {
    font-size: 1rem;
  }
  
  
  @media (max-width: 1420px) {
    .why-row h2{
        color:#f1f7f6;
        font-size:4.5em;
        line-height: 60px;
        font-weight:500;
    }

    .why-row h4{
        color:#f1f7f6;
        font-size:1em;
    }
  }

  @media (max-width: 992px) {
    .outer-circle {
        width: 275px;
        height: 275px;
    }

    .pill-title {
        font-size: 0.7rem;
      }
  }