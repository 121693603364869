@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Unbounded:wght@200..900&display=swap');

/* Font for all headings */
h1, h2, h3, h4, h5, h6 {
    font-family: "Unbounded", sans-serif;
    color:#f1f7f6
  }

  .main-wrapper {
    -webkit-overflow-scrolling: touch; /* For smooth scrolling on mobile */
  overflow-y: scroll;
  }
  
  
  
  /* Font for all paragraph and span text */
  p {
    font-family: "Inter", sans-serif;
    color:#f1f7f6b9
  }

  body{
    background-color: #042221 !important;
  }

  .ball {
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: white;
    border-radius: 50%;
    pointer-events: none; /* Ensure the ball doesn't block interactions */
    z-index: 1000; /* Keep the ball on top of everything */
    transition: transform 0.1s ease-out;
  }


html, body {
  width: 100vw;
  
}
  

a.btn {
    position: relative;
    padding: 10px 20px;
    background-color: #00DF81;
    color: #021414;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    transition: 0.5s ease-in-out;
    border: none;
    overflow: hidden;
    border-radius: 50px;
    font-weight:600;
  }
  
a.btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, #ffffff7e, transparent);
    z-index: 1;
    pointer-events: none;
    border-radius: 5px;
    animation: borderBeam 2s linear infinite;
    border: 2px solid rgba(255, 255, 255, 0.25);
    transition:  0.3s ease-in-out;
  }
  
  @keyframes borderBeam {
    0% {
      transform: translateX(-100%) scaleY(1);
    }
    50% {
      transform: translateX(100%) scaleY(1.1);
    }
    100% {
      transform: translateX(100%) scaleY(1);
    }
  }
  
a.btn:hover::before {
    background: linear-gradient(90deg, transparent, #f1f7f6, transparent);
  }
  
a.btn:hover {
    background: #00DF81;
    color:#021414;
    box-shadow: rgba(0, 223, 129, 1)    0px 7px 29px 0px;
    transition: 0.5s ease-in-out;
  }

  a.btn:focus {
    background: #00DF81;
    color:#021414;
    box-shadow: rgba(0, 223, 129, 1)    0px 7px 29px 0px;
    transition: 0.5s ease-in-out;
  }

  a.btn:active {
    background: #00DF81;
    color:#021414;
    box-shadow: rgba(0, 223, 129, 1)    0px 7px 29px 0px;
    transition: 0.5s ease-in-out;
  }

  a.btn:focus-visible {
    background: #00DF81;
    color:#021414;
    box-shadow: rgba(0, 223, 129, 1)    0px 7px 29px 0px;
    transition: 0.5s ease-in-out;
  }

a.btn:hover img {
    color: #f1f7f6; /* Optional: change text color on hover */
    box-shadow: rgba(0, 223, 129, 1)    0px 0px 50px 2px;
  }

span.span-tag{
  color:#f1f7f6;
  border: 1px solid rgba(255, 255, 255, 0.15);
  font-size:12px;
  vertical-align: middle;
  margin-right:15px;
  background-color: #042221;
  padding:5px 12px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  border-radius:50px;
}

span.span-tag2{
  color:#021414;
  border: 1px solid rgba(255, 255, 255, 0.15);
  font-size:12px;
  vertical-align: middle;
  margin-right:15px;
  background-color: #00DF81;
  padding:5px 12px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  border-radius:50px;
}


button.btn {
  position: relative;
  padding: 10px 20px;
  background-color: #00DF81;
  color: #021414;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  transition: 0.5s ease-in-out;
  border: none;
  overflow: hidden;
  border-radius: 50px;
}

button.btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, #ffffff7e, transparent);
  z-index: 1;
  pointer-events: none;
  border-radius: 5px;
  animation: borderBeam 2s linear infinite;
  border: 2px solid rgba(255, 255, 255, 0.25);
  transition:  0.3s ease-in-out;
}

@keyframes borderBeam {
  0% {
    transform: translateX(-100%) scaleY(1);
  }
  50% {
    transform: translateX(100%) scaleY(1.1);
  }
  100% {
    transform: translateX(100%) scaleY(1);
  }
}

button.btn:hover::before {
  background: linear-gradient(90deg, transparent, #f1f7f6, transparent);
}

button.btn:hover {
  background: #00DF81;
  color:#021414;
  box-shadow: rgba(0, 223, 129, 1)    0px 7px 29px 0px;
  transition: 0.5s ease-in-out;
}

button.btn:focus {
  background: #00DF81;
  color:#021414;
  box-shadow: rgba(0, 223, 129, 1)    0px 7px 29px 0px;
  transition: 0.5s ease-in-out;
}

button.btn:active {
  background: #00DF81;
  color:#021414;
  box-shadow: rgba(0, 223, 129, 1)    0px 7px 29px 0px;
  transition: 0.5s ease-in-out;
}

button.btn:focus-visible {
  background: #00DF81;
  color:#021414;
  box-shadow: rgba(0, 223, 129, 1)    0px 7px 29px 0px;
  transition: 0.5s ease-in-out;
}

button.btn:hover img {
  color: #f1f7f6; /* Optional: change text color on hover */
  box-shadow: rgba(0, 223, 129, 1)    0px 0px 50px 2px;
}

.book-meeting h1{
  color:#f1f7f6;
}

.page-header{
  background-image: url(../assets/grid.png);
  background-size:cover;
  color:#f1f7f6;
}

.allreviews-hero{
  margin-bottom:-5%;
}

.contactpage h2{
  color:#f1f7f6
}

.contactpage .pt-5{
  padding-top:1rem!important;
}

.service-page-main{
  background-color: #024d3c;
  border-radius: 50px;
}

.service-page-main h1, .service-page-bg2 h1, .All-Services-Benefits h1, .service-page-pricing h1{
  color: #f1f7f6;
}

.service-page-text span{
  color:#f1f7f6;
}

.service-page-process{
  color:#f1f7f6b9;
  font-size:1.1em;
}

.service-page-process strong{
  color:#f1f7f6;
}

.portfolio-page .page-header p {
  padding: 0rem;
}

.portfolio-page .portfolio-box-text{
  background:#00140fc9;
  height:100%;

  
}

.portfolio-page-desc p{
  font-size:1.2em;
}

.portfolio-page .portfolio-box-stats {
  display: flex;
  margin-top: auto;
  flex-direction: column;
}


.policies h2, .policies h1, .policies a, .confirmation h1{
  color:#f1f7f6;
}

.policies li{
  color:#f1f7f6b9;
}

@media (max-width: 1103px) {
  .ball{
    display:none!important;
  }
  
}

@media (max-width: 768px) {
  .scroll-container, .main-wrapper, body, html {
    overflow-y: auto !important; /* Override any hidden overflow */
    overflow-x: hidden !important; /* Prevent horizontal scroll */
    height: auto !important;
    touch-action: auto; /* Ensure touch actions are enabled */
  }
}