.brands h1{
    color:#f1f7f6;
}

.brands{
    background-image: url(../assets/brands-bg.png);
    background-size:cover;
}

.brands-outter-box {
    border: 1.5px solid rgba(241, 247, 246, 0.25);
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  
  .brand-carousel {
    display: flex;
    will-change: transform; /* Hint the browser to optimize transform */
    white-space: nowrap;
  }
  
  .brand-image {

    background-color: rgba(241, 247, 246, 0.11);
    backdrop-filter: blur(3px); /* Apply the blur effect */
    -webkit-backdrop-filter: blur(3px); /* For Safari support */
    width: 200px; /* Adjust the width of the image container */
    height: 75px; /* Adjust the height of the image container */
    justify-content: center;
    margin: 0 4px;
    padding: 10px;
    align-items: center;
    display: flex;
    border-radius: 15px;
    flex-shrink: 0;
  }
  
  .brand-image img {
    width: 100%;
    filter: grayscale(1);
    transition:0.3s;
  }
  


.brands img:hover{
    filter: grayscale(0%);
    -webkit-filter: grayscale(0%);
    -webkit-transition: 0.3s;
    transition:0.3s;
}

.brands-stat-circle {
    border: 1.5px solid rgba(241, 247, 246, 0.25);
    background-color: rgba(241, 247, 246, 0.11);
    backdrop-filter: blur(3px); /* Apply the blur effect */
    -webkit-backdrop-filter: blur(3px); /* For Safari support */
    width: 190px; /* Fixed width for the circle */
    height: 190px; /* Fixed height for the circle */
    border-radius: 50%; /* Creates a perfect circle */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0; /* Remove padding to maintain the circular shape */
}

.brands-stat-circle h3 {
    font-weight: 600;
    color: #f1f7f6;
    font-size: 2.5em;
    margin: 0;
    line-height: 1;
}

.brands-stat-circle p {
    font-weight: 400;
    color: #f1f7f6b9;
    padding:0 20px;
    font-size: 1em;
    line-height: 1.2;
    margin: 0;
}

.brands-stat-circle-outside1{
    display: flex;
    justify-content: center;
    margin-right:-12%;
    margin-top:2%;
}

.brands-stat-circle-outside4{
    display: flex;
    justify-content: center;
    margin-left:-12%;
}

.brands-stat-circle-outside2{
    display: flex;
    justify-content: center;
    margin-right:-6%;
}

.brands-stat-circle-outside3{
    display: flex;
    justify-content: center;
    margin-left:-6%;
    margin-top:1.5%;
}

.our-benefits-box{
    height:100%;
    background-color: rgba(4, 34, 33, 0.15);
    backdrop-filter: blur(1.5px); /* Apply the blur effect */
    -webkit-backdrop-filter: blur(1.5px); /* For Safari support */
    border: 1.5px solid rgba(241, 247, 246, 0.15);
    border-radius:25px;
    padding:5px 15px;
    text-align: center;
}

.our-benefits-box-head{
   text-align: center;
   background-color: #0214146b;
   width:60px;
   height:60px;
   margin: 2% auto 6% auto;
   border-radius:15px;
   border:1px solid #00DF81;
}

.our-benefits-box-head svg{
    width:35px;
    color:#00DF81;
}

.our-benefits-box-text h3{
    color:#f1f7f6;
    font-size:1.4em;
}

.benefits-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top:2%;
    gap: 15px; /* Adds spacing between the items */
}

.our-benefits-2{
    flex: 1 1 22%; 
    display: flex;
    align-items: center;
    margin: auto;
    background-color: rgba(255, 255, 255, 0.206);
    backdrop-filter: blur(5px); /* Apply the blur effect */
    -webkit-backdrop-filter: blur(5px); /* For Safari support */
    border-radius:50px;
    padding:5px 10px;
    
}

.our-benefits-2 p {
    color:#f1f7f6;
    margin: auto;
    display: flex; 
    align-items: center; 
    vertical-align: middle;
    justify-content: center;
    font-size:1em;
  }
  
  .our-benefits-2 .svg-icon {
    color:#00DF81!important;
    margin-right: 8px; 
    vertical-align: middle;
  }
  



@media (max-width: 1400px) { 
    .brands-stat-circle-outside1{
        margin-right:-9%;
    }
    
    .brands-stat-circle-outside4{
        margin-left:-9%;
    }
    
    .brands-stat-circle-outside2{
        margin-right:-4.5%;
    }
    
    .brands-stat-circle-outside3{
        margin-left:-4.5%;
    }
 }

 @media (max-width: 1400px) { 
    .brands-stat-circle-outside1{
        margin-right:-8%;
    }
    
    .brands-stat-circle-outside4{
        margin-left:-8%;
    }
    
    .brands-stat-circle-outside2{
        margin-right:-3.5%;
    }
    
    .brands-stat-circle-outside3{
        margin-left:-3.5%;
    }
 }

 @media (max-width: 992px) { 
    .brands-stat-circle-outside1{
        margin-right:-2%;
    }
    
    .brands-stat-circle-outside4{
        margin-left:-2%;
    }
    
    .brands-stat-circle-outside2{
        margin-right:-1%;
    }
    
    .brands-stat-circle-outside3{
        margin-left:-1%;
    }
 }

 @media (max-width: 767px) { 
    .brands-stat-circle-outside1{
        margin-right:-8%;
    }

    .brands-stat-circle-outside2{
        margin-left:-8%;
        margin-top:8%!important;
    }

    .brands-stat-circle-outside3{
        margin-right:-8%;
        margin-top:-12%;
    }
    
    .brands-stat-circle-outside4{
        margin-left:-8%;
        margin-top:-3%;

    }
 }

 @media (max-width: 480px) { 
    .brands-stat-circle-outside1{
        margin-right:-3%;
    }

    .brands-stat-circle-outside2{
        margin-left:-3%;
        margin-top:8%!important;
    }

    .brands-stat-circle-outside3{
        margin-right:-3%;
        margin-top:-12%;
    }
    
    .brands-stat-circle-outside4{
        margin-left:-3%;
        margin-top:-3%;

    }
 }