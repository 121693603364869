.process-number{
    color:#f1f7f6;
    align-items: center;
    display: flex;
}

.process-number h2{
    font-size:5em;
}

.process-box{
    background-color: #00140fbc;;
    padding:20px;
    border-radius:25px;
}

.process-box h4{
    margin-top:2%;
    color:#f1f7f6;
}

.psteps2{
    padding-top:2em;
}


@media (max-width: 768px) {
    .process-steps{
        padding:0 5%;
    }
  }