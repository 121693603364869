.announcement-bar {
    background: #00DF81;
    color: #212529;
    text-align: center;
    padding: .375em;
    font-size: 14px;
    font-family: "Inter", sans-serif;
    font-weight:600;
  }
  
  .announcement-bar a {
    color: #212529;
    font-weight: bold;
    margin-left: 5px;
    font-style: italic;
  }
  
  .announcement-bar a:hover {
    text-decoration: underline;
  }
  