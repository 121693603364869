.pricing-box{
    background-color: #024d3c28;
    border-radius:20px;
    padding:20px;
    border: 1.5px solid rgba(241, 247, 246, 0.25);
}

.pricing-box-enterprise{
    border: 1.5px solid rgba(0, 223, 129, 0.9);
    background: rgb(0,0,0);
        background: -moz-linear-gradient(29deg, rgba(2, 77, 60, 0.16) 60%, rgba(0,223,129,0.4) 100%);
        background: -webkit-linear-gradient(29deg, rgba(2, 77, 60, 0.16) 60%, rgba(0,223,129,0.4) 100%);
        background: linear-gradient(29deg, rgba(2, 77, 60, 0.16) 60%, rgba(0,223,129,0.4) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#00df81",GradientType=1);
}

.pricing-section-button span{
    color:#f1f7f6;
}

.pricing-box-enterprise .pricing-box-price-first{
    font-size:2em;
    vertical-align: middle;
    color:#f1f7f6;
    font-weight:bold;
    font-family: "Unbounded", sans-serif;
    padding:0px 8px;
}

.Pricing h1{
    color:#f1f7f6;
}

.pricing-box h3{
    color:#f1f7f6;
    font-size:1.4em;
}

.pricing-box-price-first{
    font-size:1em;
    vertical-align: top;
    color:#f1f7f6
}

.pricing-box-price-last{
    font-size:1em;
    vertical-align: bottom;
    color:#f1f7f6
}

.pricing-box-price{
    font-size:2em;
    vertical-align: middle;
    color:#00DF81;
    font-weight:bold;
    font-family: "Unbounded", sans-serif;
    padding:0px 8px;
}

.pricing-box .btn{
    width:100%;
}

.pricing-box li{
    list-style:none;
    color:#f1f7f6;
}

.pricing-box ul{
   margin:1em 0;
   padding:0;
}

.pricing-box ul img{
    width:30px;
}


/* Container for the pills */
.Pricing .nav-pills {
    display: inline-flex; 
    border: 1.5px solid rgba(241, 247, 246, 0.25);
    border-radius: 50px;
    padding: 2px 0px;
    position: relative;
    overflow: hidden;
}

.Pricing .nav-pills li{
    list-style: none;
}

/* Style each pill (button) */
.Pricing .nav-pills .nav-link {
    color: #f1f7f6;
    border-radius: 50px;
    padding: 4px 16px;
    margin: 3px 3px;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    position: relative;
    z-index: 1;
}

/* Active pill (when selected) */
.Pricing .nav-pills .nav-link.active {
    background-color: #00DF81;
    color: #021414;
}

/* Hover effect */
.Pricing .nav-pills .nav-link:hover {
    background-color: #32323212;
    color: #ffffff;
}

/* Sliding background effect */
.Pricing .sliding-background {
    position: absolute;
    top: 5px;
    height: 32px;
    left: 0;
    background-color: #00DF81;
    border-radius: 50px;
    transition: transform 0.4s ease-in-out;
    z-index: 0;
}

.Pricing .mobile-dropdown {
    display: none; /* Hidden by default */
  }

  .form-select {
    --bs-form-select-bg-img: url(../assets/arrow.svg);}

@media (max-width: 768px) {
    .Pricing .nav-pills {
        overflow-x: auto;
        white-space: nowrap;
    }
    .Pricing .nav-pills .nav-item {
        display: inline-block;
    }

    .Pricing .desktop-pills {
        display: none; /* Hide desktop pills on mobile */
      }
    
      .Pricing .mobile-dropdown {
        display: block; /* Show dropdown on mobile */
        margin: 0 auto;
        max-width: 300px; /* Limit dropdown width */
      }
    
      .Pricing .form-select {
        border: 1.5px solid rgba(241, 247, 246, 0.25);
            background-color: rgba(241, 247, 246, 0.11);
            backdrop-filter: blur(1px); /* Apply the blur effect */
            -webkit-backdrop-filter: blur(1px);
        border-radius:50px;
        color: #f1f7f6;
        padding: 10px 15px;
      }
}
