.about-hero span{
    color:#00DF81;
}

.about-hero{
    background-image: url(../assets/workingbg.png);
    background-size:cover;
    height: 50vh;
    display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: flex-end; /* Align items to the bottom */
  padding:15px;
  color:#f1f7f6;
  border-radius:25px;
  text-align: center;
  margin-top:0.75rem;
  margin-bottom:1em;
}

.about-hero h2 {
    line-height: 45px;
    font-size: 70px;
    padding: 0 130px;
}

.about-title h1{
    color:#f1f7f6;
}

.about-text p{
    font-size:23px;
}

.accordion-item {
    background: #00140fbc !important;
    color:#f1f7f6b9!important;
    margin-bottom: 1em;
    border:none!Important;
    border-radius: 25px !important;
}

.accordion-button::after {
    background-image: url(../assets/arrowdown.svg);
    transition: 0.3s ease;
}

.accordion-button:not(.collapsed)::after {
    background-image: url(../assets/arrowdown.svg);
    transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-header:hover .accordion-button{
    color:#00DF81!important;
    padding-left:50px!important;
    transition: 0.3s ease;
}
.accordion-header .accordion-button{
    transition: 0.3s ease;
}

.accordion-header:hover .accordion-button::after {
    background-image: url(../assets/arrowdowngreen.svg);
    transition: 0.3s ease;
}

.accordion-item:first-of-type {
    border-radius: 25px !important;
}

.accordion-button:not(.collapsed) {
    background: transparent !important;
}

.accordion-button{
    background: transparent !important;
    font-size: 0.7em;
}

.accordion-button {
    color: #f1f7f6;
    padding: 25px!important;
}

.accordion-button:not(.collapsed) {
    color: #f1f7f6;
}

.accordion-button:focus {
    z-index: 3;
    outline: 0;
    box-shadow: none!important;
}

.accordion-button:active {
    z-index: 3;
    outline: 0;
    box-shadow: 0px!important;
}

.accordion{
    --bs-accordion-border-color: #00140fbc;
}

.accordion-button:focus-visible {
    z-index: 3;
    outline: 0;
    box-shadow: 0px!important;
}

.accordion-body {
    padding: 0px 20px 20px!important;
    font-size: 1.2em;
}

@media (max-width: 1200px) {
    .about-hero h2 {
        line-height: 30px;
        font-size: 50px;
        padding: 0 70px;
    }
  }

@media (max-width: 900px) {
    .about-hero h2 {
        line-height: 30px;
        font-size: 50px;
        padding: 0 70px;
    }
  }

@media (max-width: 768px) {
    .about-hero h2 {
        line-height: 45px;
        font-size: 50px;
        padding: 0 0px;
    }
  }