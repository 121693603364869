.campaign-circle img{
    background:#00DF81;
    width:70px;
    height:70px;;
    border-radius:50px;
}

.hero{
    background-image: url(../assets/grid.png);
    background-size:cover;
    color:#f1f7f6;
}

.hero-star-text{
    color:#f1f7f6;
    font-size:1em;
    font-family: "Unbounded", sans-serif;
    vertical-align: middle;
    padding-left:10px;
    font-weight:300;
}

.hero h1{
    font-family: "Unbounded", sans-serif;
}

.hero-text-bg {
    position: relative;
    display: inline-block;
    color:#021414;
    z-index: 2;
  }
  
  .hero-text-bg::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background-color: #00DF81;
    transform: translate(-50%, -50%) rotate(4deg);
    z-index: -1;
  }

  .hero p{
    font-family: "Inter", sans-serif;
    font-weight:400;
    color:#f1f7f6b9;
  }

  