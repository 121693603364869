.projects-portfolio .portfolio-box-text{
    margin-top:0%;
    height:100%;
}

.projects-portfolio .portfolio-box-text {
    display: flex;
    flex-direction: column;
}
  
  .projects-portfolio .portfolio-box-stats {
    display: flex;
    margin-top: auto;
  }

  .projects-portfolio .portfolio-box-text span{
    width: 103px!important;
  }

  .projects-portfolio a{
    text-decoration: none;
  }

  .projects-portfolio .portfolio-box-text{
    background-color: #00140f6c!important;
    color:#f1f7f6!important;
  }

  .projects-portfolio .portfolio-box-text p, h3{
    color:#f1f7f6!important;
  }

  .projects-portfolio .portfolio-box img {
    height: 100% !important;
  }

  .projects-portfolio .portfolio-box-img {
    height: 100% !important;
  }