.services{
    background:#03614c;
    border-top:1px solid rgba(255, 255, 255, 0.2);
    color:#f1f7f6;
    margin-top:-100px;
    border-top-left-radius: 50px;;
    border-top-right-radius: 50px;;
}

.services h1{
    font-size:2.5em;
    font-weight:400;
}

.services-box{
    background-color: #024d3c;
    padding:15px 25px;
    border-radius:30px;
    margin:3px;
    transition: 0.3s ease;
    min-height: 98%;
}

.services-box-icon svg{
    width:35px;
    fill:#00DF81;
    transition: filter 0.3s ease;
}

.services-box-icon{
    background-color: #106754b4;
    width: 50px;
    height:50px;
    display: flex; /* Enables flexbox */
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    text-align: center;
    border-radius:13px;
}

.services-box p{
    font-family: "Inter", sans-serif;
    font-weight:400;
    font-size:1.1em;
    color:#f1f7f6b9;
}

a.services-box-link{
    color:#f1f7f6;
    text-decoration: none;
}

.services-box-button h5{
    color:#f1f7f6;
    font-family: "Unbounded", sans-serif;
    font-weight: 500;
    font-size:0.8em;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
}

.services-box-button span {
    display: inline-flex; 
    justify-content: center; 
    align-items: center; 
    border-radius: 50px;
    border: 1.5px solid rgba(255,255,255,0.2);
    width: 25px;
    height: 25px;
    transition: 0.3s ease;
}

.services-box-button svg{
    width:13px;
    transform: rotate(-45deg);
    fill:#f1f7f6;
    transition: 0.3s ease;
}

.services-box:hover .services-box-button h5{
    text-decoration: underline;;
    transition: 0.3s ease;
}

.services-box:hover .services-box-icon svg{
    transition: filter 0.3s ease;
    animation: svgspin 0.6s;
    filter: drop-shadow( 0px 0px 10px rgba(0, 223, 129, 1));
  }

@keyframes svgspin {
    0% { transform: rotate(0deg); }
    100% {  transform: rotate(359deg); }
}

.services-box:hover{
    box-shadow: rgba(255, 255, 255, 0.05)  0px 0px 13px 0px;
}

.services-box:hover .services-box-button svg{
    transform: rotate(0deg);
    transition: 0.3s ease;
    fill:#021414;
}

.services-box:hover .services-box-button span{
    background-color: #00DF81;
}

.services-row .row>* {
    padding-right: 0px;
    padding-left: 0px;
}