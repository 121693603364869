.portfolio{
    background-color: #F1F7F6;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
    padding-bottom:6%!important;
}

.portfolio-row{
    background-color: #E3ECEB;
    padding:2rem 2rem;
    border-radius:25px;
}

.portfolio-box{
    overflow: hidden;
}

.portfolio a{
    text-decoration: none!important;
}

.portfolio-box:hover.portfolio-box img{
    transition: 0.3s ease;
    transform: scale(1.05);
}

.portfolio-box:hover .portfolio-box-text{
    border:2.5px solid #465E5D;
    transition: 0.3s ease;
}

.portfolio-box:hover .portfolio-box-img{
    border:2.5px solid #465E5D;
    transition: 0.3s ease;
}

.portfolio-box-img{
    width: 100%;
    overflow: hidden;
    transition: 0.3s ease;
    border-radius:25px;
}


.portfolio-box img{
    border-radius:25px;
    /* border:2.5px solid #042221; */
    transition: 0.3s ease;
}

.portfolio-box-text{
    /* border:2.5px solid #042221; */
    border:2.5px solid transparent;
    background-color: #d2dad8;
    margin-top:1%;
    border-radius:25px;
    padding:20px;
    transition: 0.3s ease;
}

.portfolio-box p{
    color:#042221b9;
}

.portfolio-box-text h3{
margin-top:2%;
    color: #021414;
}

.portfolio-box-stats{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top:2%;
    gap: 55px;
}

.portfolio-stat{
    text-align: center;
}

.portfolio-stat h2{
    color:#00DF81;
    font-size:70px;
    line-height:60px;
}

.portfolio-box-padding{
    padding:0 8px!important;
}

@media (max-width: 1200px) { 
    .portfolio-stat h2{
        color:#00DF81;
        font-size:50px;
        line-height:60px;
    }

    .portfolio-box-stats{
        gap: 10px;
    }
 }

@media (max-width: 992px) { 
    .portfolio-stat h2{
        color:#00DF81;
        font-size:50px;
        line-height:60px;
    }

    .portfolio-box-stats{
        gap: 20px;
    }
 }