/* General navbar styling */
.navbar {

  }


  .bg-dark{
    background:#042221!important;
  }

  .navbar-desktop{
    background-color: rgba(4, 34, 33, 0.6);
    backdrop-filter: blur(10px); /* Apply the blur effect */
    -webkit-backdrop-filter: blur(10px); /* For Safari support */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0; /* This ensures it sticks to the top */
    z-index: 1000; 
    padding:1.5rem 2rem;
    border-bottom:1px solid rgba(255, 255, 255, 0.2);
  }

  .navbar-desktop ul{
    list-style:none;
    padding:0;
    margin:0;
    background:transparent;
    border-radius:50px;
  }

  .navbar-desktop li{
    display:inline;
    padding:3px;
  }

  .navbar-desktop-links a{
    font-size:0.79em;
    color:#f1f7f6;
    text-decoration: none;
    font-family: "Unbounded", sans-serif;
    padding: 0.6rem 0.8rem;
    border-radius:25px;
    transition: 0.3s ease-in-out;
    border:1px solid rgba(255,255,255,0);
  }

  .navbar-desktop-links ul li a.active {
    border:1px solid rgba(255,255,255,0.15);
    background-color: #021414;
    color: #f1f7f6; /* Active link color */
    transition: 0.3s ease-in-out;
  }

  .navbar-desktop-links ul li a:hover {
    border:1px solid rgba(255,255,255,0.15);
    color: #f1f7f6; /* Active link color */
    transition: 0.3s ease-in-out;
  }

  .navbar-desktop-links ul li a.btn {
    position: relative;
    padding: 10px 20px;
    background-color: #00DF81;
    color: #021414;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    transition: 0.5s ease-in-out;
    border: none;
    overflow: hidden;
    border-radius: 50px;
  }
  
  .navbar-desktop-links ul li a.btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, #ffffff7e, transparent);
    z-index: 1;
    pointer-events: none;
    border-radius: 5px;
    animation: borderBeam 2s linear infinite;
    border: 2px solid rgba(255, 255, 255, 0.25);
    transition:  0.3s ease-in-out;
  }
  
  @keyframes borderBeam {
    0% {
      transform: translateX(-100%) scaleY(1);
    }
    50% {
      transform: translateX(100%) scaleY(1.1);
    }
    100% {
      transform: translateX(100%) scaleY(1);
    }
  }
  
  .navbar-desktop-links ul li a.btn:hover::before {
    background: linear-gradient(90deg, transparent, #f1f7f6, transparent);
  }
  
  .navbar-desktop-links ul li a.btn:hover {
    box-shadow: rgba(0, 223, 129, 1)    0px 7px 29px 0px;
    transition: 0.5s ease-in-out;
  }

  .navbar-desktop-links ul li a.btn:hover img {
    color: #f1f7f6; /* Optional: change text color on hover */
    box-shadow: rgba(0, 223, 129, 1)    0px 0px 50px 2px;
  }
  
  .mobile-nav ul li a.btn {
    font-size: 1.7em;
    position: relative;
    padding: 10px 20px;
    background-color: #00DF81;
    color: #021414;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    transition: 0.5s ease-in-out;
    border: none;
    overflow: hidden;
    border-radius: 50px;
  }
  
  .mobile-nav ul li a.btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, #ffffff7e, transparent);
    z-index: 1;
    pointer-events: none;
    border-radius: 5px;
    animation: borderBeam 2s linear infinite;
    border: 2px solid rgba(255, 255, 255, 0.25);
    transition:  0.3s ease-in-out;
  }
  
  @keyframes borderBeam {
    0% {
      transform: translateX(-100%) scaleY(1);
    }
    50% {
      transform: translateX(100%) scaleY(1.1);
    }
    100% {
      transform: translateX(100%) scaleY(1);
    }
  }
  
  .mobile-nav ul li a.btn:hover::before {
    background: linear-gradient(90deg, transparent, #f1f7f6, transparent);
  }
  
  .mobile-nav ul li a.btn:hover {
    box-shadow: rgba(0, 223, 129, 1)    0px 0px 40px 10px;
    transition: 0.5s ease-in-out;
  }

  .mobile-nav ul li a.btn:hover img {
    color: #f1f7f6; /* Optional: change text color on hover */
    box-shadow: rgba(0, 223, 129, 1)    0px 0px 40px 10px;
  }
  
  
  /* Desktop-only classes */
  .desktop-only {
    display: block;
  }
  
  .mobile-only {

    display: none!important;
    border-bottom:1px solid rgba(255, 255, 255, 0.2);
  }

  .mobile-nav-logo {
    position: absolute;
    top: 6.5%;
    left: 2%;
  }
  
  /* Contact info */
  .contact-info {
    position: absolute;
    bottom: 20px;
    right: 20px;
    text-align: right;
  }
  
  .contact-info p {
    margin: 0;
    font-size: 14px;
    color: #f1f7f6;
  }

  .contact-info h1{
    font-family: "Unbounded", sans-serif;
    color:#fff
  }

  .contact-info p{
    font-family: "Inter", sans-serif;
    font-weight:500;
    font-size:1.2em;
  }

  @media (max-width: 1103px) {
    .desktop-only {
      display: none!important;
    }
  
    .mobile-only {
      display: block!important;
    }
  }
  
  /* Show the mobile navbar and hide the desktop navbar on smaller screens */
  @media (max-width: 768px) {
    .desktop-only {
      display: none;
    }
  
    .mobile-only {
      display: block;
      background-color: rgba(4, 34, 33, 0.4);
      backdrop-filter: blur(10px); /* Apply the blur effect */
      -webkit-backdrop-filter: blur(10px); /* For Safari support */
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      position: sticky!important;
      top: 0; /* This ensures it sticks to the top */
      z-index: 1000; 
    }
  }
  
  /* Mobile-specific styles */
  .burger-icon {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .burger-icon .navbar-toggler-icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-color: transparent;
    position: relative;
    transition: 0.3s ease-in-out;
  }

  .navbar-toggler-icon {
    background-image: url("../assets/lines.svg")!important;
  }
  
  /* Mobile nav (slide from right to left) */
  .mobile-nav {
    position: fixed;
    top: 0;
    right: -100%; /* Initially hidden off-screen */
    width: 100%;
    height: 100%;
    background-color: #042221;
    color: #f1f7f6;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: right 0.3s ease; /* Slide-in transition */
  }
  
  .mobile-nav.open {
    right: 0; /* Slide into view */
  }
  
  .mobile-nav .close-btn {
    position: absolute;
    top: 5%;
    right: 2%;
    font-size: 36px;
    background: none;
    border: none;
    color: #f1f7f6;
    cursor: pointer;
  }
  
  .mobile-menu {
    list-style: none;
    padding: 0;
  }
  
  .mobile-menu li {
    margin: 20px 0;
  }
  
  .mobile-menu li a {
    font-size: 20px!important;
    color: #f1f7f6;
    text-decoration: none;
    font-family: "Unbounded", sans-serif;
    font-size:2em;
    font-weight:400;
    transition: ease-in-out 0.2s;
  }
  
  .mobile-menu li a:hover {
    color: #00DF81;
    transition: ease-in-out 0.2s;
    text-decoration: underline;
  }
  
  /* Mobile "Get Started" button inside mobile menu */
  .mobile-get-started {
    margin-top: 20px;
    padding: 0.75rem 2rem;
    font-size: 1.2rem;
  }
  

  @media (max-width: 1103px) {
    
  }