.footer-container{
    background-color: #00140f6c;
    padding:20px;
    border-radius: 25px;;
    color:#f1f7f6;
}

.footer-left-side-box{
    background-color: #00140fbc;
    padding:15px 25px;
    border-radius: 25px;;
    margin-bottom:0.5em;
}

.footer-left-side-box svg{
    padding-bottom:1em;
    color:#00dc7e;
}

.footer-left-side-box h4{
    font-size:1em;
}

.footer-links{
    padding:10px;
}

.footer-links h5{
    color:#00dc7e;
}

.footer-links ul{
    padding:0;
    margin:0;
}

.footer-links li{
    list-style:none;
    font-weight:500;
    padding:5px 0px;
}

.footer-links a{
    text-decoration: none;
    color:#f1f7f6;
}

.footer-links a:hover{
    text-decoration: underline;
}

@media (max-width: 768px) {
    .boring-stuff li{
        display: inline;
        padding:5px;
    }
}